import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Millions of Participants`}</h1>
    <ul>
      <li parentName="ul">{`Yes, this is possible, and the methodology was designed for it.`}</li>
      <li parentName="ul">{`It would present a significantly higher `}<a parentName="li" {...{
          "href": "/Moderation",
          "title": "👹 Moderation"
        }}>{`👹 Moderation`}</a>{` burden.`}</li>
      <li parentName="ul">{`It is of dubious benefit, given the `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Law_of_large_numbers"
        }}>{`law of large numbers`}</a>{`. If you have a population this large, a random sampling of 2000 to 10,000 will provide a robust dataset.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      